import React, { useState, useEffect } from 'react';
import { Row, Col, Card, Tabs, Tab, Tooltip, OverlayTrigger, Form } from 'react-bootstrap';
import * as FDS from '@arctravel/react-fds/lib';
import { useSelector } from 'react-redux';
import './charts.scss';

interface BIProps {
    data: any,
    template: any,
    showReport: boolean
}

export const BI = (props: BIProps) => {
    const customersRef = useSelector((state: any) => state.customersRef);
    const [key, setKey] = useState('tab0');
    const [rtData, setRtData] = useState(typeof props.data === "string" ? {} : props.data);
    const [charts, setCharts] = useState(JSON.parse(JSON.stringify(props.template.chartsLists)));

    const checkExtensions = (field: any, v: any, vi: number) => {
        return (
            (
                (field.keys.extensionAllowed?.[vi] === true) ||
                (field.keys.extensionAllowed?.[vi] === "INTERNAL" && customersRef.viewAs.org === "ARC")
            ) &&
            (rtData[field.name]?.results?.[0]?.[v] !== rtData[field.name].results[0][v + field.keys?.multiValueExtensions?.[0]?.key])
        )
    }

    const getChart = (field: any) => {
        let chart: any = (
            <div className="card loadMore">
                <div className="bar mb-2" style={{ width: "50%" }} />
                <div className="bar mb-2" style={{ width: "30%" }} />
                <div className="bar mb-2" style={{ width: "60%" }} />
                <div className="bar" style={{ width: "45%" }} />
            </div>
        );

        if (!rtData[field.name]) {
            rtData[field.name] = { results: [{}] };
        }

        if (!rtData[field.name] || Object.keys(rtData[field.name]?.results).length === 0 || (rtData[field.name]?.results?.length === 1 && Object.keys(rtData[field.name]?.results?.[0] || {}).length === 0)) {
            return props.showReport ?
                <div className="card p-3">
                    No data available
                </div> :
                chart;
        }

        switch (field.type) {
            case "SummaryCards":
                chart = (
                    <Row className="summaryWrap">
                        <Col>
                            {field.keys.values.length > 4 && !field.rowCols ?
                                <>
                                    <i className='fds-glyphs-arrow-left3 icons left' data-testid="prevBtn" onClick={(ev: any) => {
                                        const sw: any = document.querySelector(".summary.chartsWrap");
                                        const prev = sw.scrollLeft;
                                        sw.scrollLeft -= 350;
                                        if (sw.scrollLeft === 0 || sw.scrollLeft === prev) {
                                            const left: any = document.querySelector(".icons.left");
                                            left.style.display = "none";
                                        }
                                        if (sw.scrollLeft < prev) {
                                            const left: any = document.querySelector(".icons.right");
                                            left.style.display = "block";
                                        }
                                    }} />
                                    <i className='fds-glyphs-arrow-right3 icons right' data-testid="nextBtn" onClick={() => {
                                        const sw: any = document.querySelector(".summary.chartsWrap");
                                        const prev = sw.scrollLeft;
                                        sw.scrollLeft += 350;
                                        if (sw.scrollLeft > prev) {
                                            const left: any = document.querySelector(".icons.left");
                                            left.style.display = "block";
                                        }
                                        if (sw.scrollLeft === prev || sw.scrollLeft - prev < 350) {
                                            const left: any = document.querySelector(".icons.right");
                                            left.style.display = "none";
                                        }
                                    }} />
                                </> : null
                            }
                            {<Row className={`summary chartsWrap tilesWrap ${field.rowCols && "wrapCards"}`}>
                                {field.keys.values?.map((v: any, vi: number) => {
                                    return (
                                        <Col className={`${(vi === 0 || ((vi + 1) / field.rowCols) === 1) && "ps-0"} mb-3 ${field.rowCols && 'col-md-' + field.rowCols} ${field.keys.values.length - 1 === vi && !field.rowCols ? 'pe-0' : ''}`} key={vi}>
                                            <Card className="primary left">
                                                <Card.Body>
                                                    <Card.Text className='pb-1'>

                                                        {!Number.isNaN(rtData[field.name].results[0][v + "_percent_diff"]) && (
                                                            !checkExtensions(field, v, vi) || !field.keys?.multiValueExtensions || field.keys?.multiValueExtensions?.length <= 0
                                                        ) ?
                                                            <div className={`trend right ${rtData[field.name].results[0][v + "_percent_diff"] >= 0 ? 'successTxt' : 'dangerTxt'}`}>
                                                                <span className='trendText pe-2'>{rtData[field.name].results[0][v + "_percent_diff"] ? FDS.numberFormatTxt(rtData[field.name].results[0][v + "_percent_diff"] || 0, 2, field.keys.percentSymbol || field.keys.symbol[vi]) : ""}</span>
                                                                {rtData[field.name].results[0][v + "_percent_diff"] === 0 ?
                                                                    null :
                                                                    rtData[field.name].results[0][v + "_percent_diff"] > 0 ?
                                                                        <i className='fds-glyphs-arrow-up-right' /> :
                                                                        <i className='fds-glyphs-arrow-down-right' />
                                                                }
                                                            </div> : null
                                                        }

                                                        {
                                                            ((field.keys?.multiValueExtensions && checkExtensions(field, v, vi)) ? field.keys?.multiValueExtensions : [{ key: "" }])?.map((mv: any, mvi: number) => {
                                                                return (
                                                                    <>
                                                                        {`${v}${mv.key}` in rtData[field.name].results[0] ?
                                                                            <>
                                                                                {field.keys?.multiValueExtensions && mvi > 0 ? <hr style={{ margin: "0.5rem 0" }} /> : null}
                                                                                <h1 style={{ display: "flex" }} key={mvi}>
                                                                                    {FDS.numberFormatTxt((rtData[field.name]?.results?.[0]?.[v + mv.key] || 0), 2, field.keys.symbol?.[vi])}

                                                                                    {checkExtensions(field, v, vi) &&
                                                                                        <span className='rightLabel'>
                                                                                            {`${v}${mv.key}_percent_diff` in rtData[field.name].results[0] && rtData[field.name].results[0][v + mv.key + "_percent_diff"] > 0 ?
                                                                                                <OverlayTrigger
                                                                                                    placement="auto"
                                                                                                    overlay={<Tooltip>{FDS.numberFormatTxt(rtData[field.name].results[0][v + mv.key + "_diff"], 2, field.keys.symbol?.[vi])}</Tooltip>}
                                                                                                >
                                                                                                    <i className='fds-glyphs-arrow-up successTxt' />
                                                                                                </OverlayTrigger>
                                                                                                :
                                                                                                `${v}${mv.key}_percent_diff` in rtData[field.name].results[0] && rtData[field.name].results[0][v + mv.key + "_percent_diff"] < 0 ?
                                                                                                    <OverlayTrigger
                                                                                                        placement="auto"
                                                                                                        overlay={<Tooltip>{FDS.numberFormatTxt(rtData[field.name].results[0][v + mv.key + "_diff"], 2, field.keys.symbol?.[vi])}</Tooltip>}
                                                                                                    >
                                                                                                        <i className='fds-glyphs-arrow-down dangerTxt' />
                                                                                                    </OverlayTrigger> :
                                                                                                    ""
                                                                                            }
                                                                                            <span className='ps-1'>{mv.label}</span>
                                                                                        </span>
                                                                                    }
                                                                                </h1>
                                                                            </> :
                                                                            null
                                                                        }
                                                                    </>
                                                                )
                                                            })
                                                        }

                                                        <h3 className='summaryCardLabel'>{field?.keys?.vLabels?.[vi] || v}</h3>
                                                    </Card.Text>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                    )
                                })}
                            </Row>}
                        </Col>
                    </Row>
                );
                break;
            case "XYChart":
                chart = (
                    <FDS.XYChart
                        id={field.aliasName ? field.aliasName : field.name}
                        title={field.label}
                        data={field.keys.sort ? rtData[field.name].results.sort((a: any, b: any) => a[field.keys.sort] > b[field.keys.sort] ? -1 : 1) : [...rtData[field.name].results]}
                        totalRecords={rtData[field.name].totalRecords}
                        enableBars={field.enableBars}
                        hideBarsAt={field.hideBarsAt}
                        enableLines={field.enableLines}
                        enableLinesForIndex={field.enableLinesForIndex}
                        enableCurvedLine={field.enableCurvedLine}
                        enableCurvedLineForIndex={field.enableCurvedLineForIndex}
                        enableDots={field.enableDots}
                        enableDotsForIndex={field.enableDotsForIndex}
                        enableArea={field.enableArea}
                        enableAreaForIndex={field.enableAreaForIndex}
                        enableCurvedArea={field.enableCurvedArea}
                        enableCurvedAreaForIndex={field.enableCurvedAreaForIndex}
                        enableValues={field.enableValues}
                        disableValuesForIndexes={field.disableValuesForIndexes}
                        sideBySide={field.sideBySide}
                        labelIndex={field.labelIndex}
                        labels={field.labels}
                        keys={{ ...field.keys, values: [...(field.keys.compareTrendValues || field.keys.value || field.keys.values), ...(field.keys.past || [])] }}
                        style={{ width: field.width, height: field.height || 460 }}
                        sortKey={field.key?.sort}
                        theme={field.theme}
                    />
                );
                break;
            case "BarChart":
                chart = (
                    <FDS.BarChart
                        id={field.aliasName ? field.aliasName : field.name}
                        title={field.label}
                        orientation={field.orientation}
                        stacked={field.stacked}
                        merged={field.merged}
                        sideBySide={field.sideBySide}
                        labelIndex={field.labelIndex}
                        enableValues={field.enableValues}
                        data={field.keys.sort ? rtData[field.name].results.sort((a: any, b: any) => a[field.keys.sort] > b[field.keys.sort] ? -1 : 1) : [...rtData[field.name].results]}
                        totalRecords={rtData[field.name].totalRecords}
                        labels={field.labels}
                        keys={{ ...field.keys, values: [...(field.keys.compareTrendValues || field.keys.value || field.keys.values), ...(field.keys.past || [])] }}
                        style={{ width: field.width, height: field.height || 500 }}
                        sortKey={field.key?.sort}
                        theme={field.theme}
                    />
                );
                break;
            case "DonutChart":
                chart = (
                    <FDS.DonutChart
                        id={field.name}
                        title={field.label}
                        semicircle={field.semicircle}
                        data={field.keys.sort ? rtData[field.name].results.sort((a: any, b: any) => a[field.keys.sort] > b[field.keys.sort] ? -1 : 1) : rtData[field.name].results}
                        enableValues={field.enableValues}
                        totalRecords={rtData[field.name].totalRecords}
                        showTotal={field.showTotal}
                        showPercentage={field.showPercentage}
                        hideLegends={field.hideLegends}
                        hideToggleButton={field.hideToggleButton}
                        hideDownloadButton={field.hideDownloadButton}
                        totalText={field.totalText}
                        sideBySide={field.sideBySide}
                        keys={field.keys}
                        style={{ width: field.width }}
                        sortKey={field.key?.sort}
                        theme={field.theme}
                    />
                );
                break;
            case "BubbleChart":
                chart = (
                    <FDS.BubbleChart
                        id={field.name}
                        title={field.label}
                        data={field.keys.sort ? rtData[field.name].results.sort((a: any, b: any) => a[field.keys.sort] > b[field.keys.sort] ? -1 : 1) : rtData[field.name].results}
                        totalRecords={rtData[field.name].totalRecords}
                        enableValues={field.enableValues}
                        showTotal={field.showTotal}
                        totalText={field.totalText}
                        sideBySide={field.sideBySide}
                        keys={field.keys}
                        style={{ width: field.width }}
                        sortKey={field.key?.sort}
                        theme={field.theme}
                    />
                );
                break;
            case "TreemapChart":
                chart = (
                    <FDS.TreemapChart
                        id={field.name}
                        title={field.label}
                        data={Array.isArray(rtData[field.name].results) ? {
                            children: [{
                                [field.keys.label]: field.keys.legends || field.keys.label,
                                children: field.keys.sort ? rtData[field.name]?.results?.sort((a: any, b: any) => a[field.keys.sort] > b[field.keys.sort] ? -1 : 1) : [...rtData[field.name].results]
                            }]
                        } : rtData[field.name].results}
                        totalRecords={rtData[field.name].results.totalRecords}
                        sideBySide={field.sideBySide}
                        enableValues={field.enableValues}
                        keys={field.keys}
                        style={{ width: field.width, height: field.height || 600 }}
                        sortKey={field.key?.sort}
                        theme={field.theme}
                    />
                );
                break;
            case "BarPercentChart":
                chart = (
                    <FDS.BarPercentChart
                        id={field.name}
                        title={field.label}
                        data={rtData[field.name]?.results?.[0]?.[field.keys?.values?.[0] || 0] || 0}
                        orientation="H"
                        style={{ width: field.width, height: field.height || 100 }}
                    />
                );
                break;
            case "DonutPercentageChart":
                break;
            case "TableChart":
                console.log("Tabel", rtData[field.name].results)
                chart = (
                    <FDS.TableChart
                        id={field.name}
                        title={field.label}
                        data={field.keys.sort ? rtData[field.name].results.sort((a: any, b: any) => a[field.keys.sort] > b[field.keys.sort] ? -1 : 1) : rtData[field.name].results}
                        keys={field.keys}
                        sortKey={field.key?.sort}
                    />
                );
                break;
            case "CirclePackageChart":
                chart = (
                    <FDS.CirclePackageChart
                        id={field.name}
                        title={field.label}
                        data={field.keys.sort ? rtData[field.name].results.sort((a: any, b: any) => a[field.keys.sort] > b[field.keys.sort] ? -1 : 1) : rtData[field.name].results}
                        totalRecords={rtData[field.name]?.results?.children?.length}
                        enableLabels={true}
                        enableValues={field.enableValues}
                        showTotal={true}
                        totalText="Total Product Count"
                        keys={field.keys}
                        style={{ width: field.width }}
                        sortKey={field.key?.sort}
                    />
                );
                break;
            default:

        }

        return chart;
    }

    const getCompareTrendOption = (list: any, wrap: any, tab: any, li: number) => {
        return (
            <>
                {wrap.compareTrendOptions?.length > 0 &&
                    <Row id={"compareTrendOptions_" + wrap.name} className='compareTrendOptions pt-3'>
                        <Col style={{ flex: 0 }}>
                            <label htmlFor="" className='pt-1 pb-1'>Compare:</label>
                        </Col>
                        {wrap.compareTrendOptions?.map((tOp: string, tOpi: number) => {
                            return (
                                <Col className='compareChecks' key={tOp} style={{ flex: 0 }}>
                                    <Form.Check type="switch" data-testid={"checkOn" + tOpi} label={tOp} name={tOp} style={{ display: 'inline-block' }} onChange={(ev: any) => {
                                        const yyName = "_" + ev.target.name;

                                        if (ev.target.checked) {
                                            (tab || wrap)?.fields?.map((field: any) => {
                                                if (!field.keys?.compareTrendValues) {
                                                    field.keys.compareTrendValues = [];
                                                }
                                                field.keys?.values.sort((a: string, b: string) => a < b ? 1 : -1).forEach((value: any) => {
                                                    const name = value + yyName;
                                                    field.keys?.compareTrendValues?.push(name);
                                                    field.keys?.compareTrendValues?.sort((a: string, b: string) => a < b ? 1 : -1);
                                                    field.keys?.legends?.push(field.keys.legendToAppend ? field.keys.legendToAppend + ev.target.name : name);
                                                    field.keys?.legends?.sort((a: string, b: string) => a < b ? 1 : -1);
                                                });
                                                return field;
                                            });
                                        } else {
                                            (tab || wrap)?.fields?.map((field: any) => {
                                                field.keys?.values?.sort((a: string, b: string) => a < b ? 1 : -1)?.forEach((value: any) => {
                                                    const name = value + yyName;
                                                    const pos = field.keys?.compareTrendValues?.indexOf(name);
                                                    field.keys?.compareTrendValues?.splice(pos, 1).sort((a: string, b: string) => a < b ? 1 : -1);
                                                    field.keys?.legends?.splice(pos, 1).sort((a: string, b: string) => a < b ? 1 : -1);
                                                });
                                                return field;
                                            });
                                        }
                                        setCharts((prev: any) => {
                                            prev[li] = list;
                                            return [...prev]
                                        })
                                    }} />
                                </Col>
                            )
                        })}
                    </Row>
                }
            </>
        )
    };

    const getTab = (list: any, li: number) => {
        return list.wraps.map((wrap: any, wi: number) => {
            if (wrap.tabs) {
                return (
                    <div key={wi}>
                        {wrap.showName ? <h2 className='mt-3'>{wrap.label?.indexOf("{Your Agency}") >= 0 ? wrap.label?.replace("{Your Agency}", customersRef?.customer?.name ? customersRef.customer.name : "Your") : wrap.label}</h2> : null}
                        <Tabs onClick={() => { setRtData(JSON.parse(JSON.stringify(props.data))) }} className='wrapTabs mt-3' defaultActiveKey={wrap.defaultTab} transition={false}>
                            {wrap.tabs.map((tab: any, ti: number) => {
                                return (
                                    <Tab key={ti} className={`row ${wrap.compareTrendOptions?.length > 0 ? "m-0" : ""}`} eventKey={tab.name} title={tab.label}>
                                        {getCompareTrendOption(list, wrap, tab, li)}
                                        {tab.fields?.map((field: any) => {
                                            return (
                                                <>
                                                    {
                                                        !field.showFor || customersRef.customer?.data_content?.indexOf(field.showFor?.subscription) >= 0 ?
                                                            <Col className={wrap.compareTrendOptions?.length > 0 ? "compareTrendOptions_cardWrap" : ""} key={field.name} md={field.spacing}>
                                                                {getChart(field)}
                                                            </Col> :
                                                            null
                                                    }
                                                </>
                                            )
                                        })}
                                    </Tab>
                                )
                            })}
                        </Tabs>
                    </div >
                )
            } else {
                return (
                    <div key={wi}>
                        {wrap.showName ? <h2 className='mt-3'>{wrap.label.indexOf("{Your Agency}") >= 0 ? wrap.label.replace("{Your Agency}", customersRef?.customer?.name ? customersRef.customer.name : "Your") : wrap.label}</h2> : null}
                        {wrap.compareTrendOptions?.length > 0 &&
                            <Row id={"compareTrendOptions_" + wrap.name} className='compareTrendOptions pt-3'>
                                {getCompareTrendOption(list, wrap, null, li)}
                            </Row>
                        }
                        <Row className={wrap.compareTrendOptions?.length > 0 ? "compareTrendOptions_cardWrap" : ""} key={wi}>
                            {
                                wrap.fields.map((field: any, fi: number) => {
                                    return (
                                        <>
                                            {
                                                !field.showFor || customersRef.customer?.data_content?.indexOf(field.showFor?.subscription) >= 0 ?
                                                    <Col className="mt-3 mb-3" key={fi} md={field.spacing}>
                                                        {
                                                            field.fieldTabs ?
                                                                <Card className='chartSection'>
                                                                    <Tabs className='fieldTabs'>
                                                                        {[...field.keys.values]?.map((valuesObj: any, vai: number) => {
                                                                            const newField = JSON.parse(JSON.stringify(field));
                                                                            newField.keys.values = valuesObj.values;
                                                                            return (<Tab key={vai} eventKey={newField.name + vai} title={valuesObj.tabName}>{getChart(newField)}</Tab>);
                                                                        })}
                                                                    </Tabs>
                                                                </Card> :
                                                                getChart(field)
                                                        }
                                                    </Col> :
                                                    null
                                            }
                                        </>
                                    )
                                })
                            }
                        </Row>
                    </div>
                )
            }
        })
    }

    useEffect(() => {
        setKey('tab0');
        console.log("PDD:", props.data);
        setRtData(typeof props.data === "string" ? {} : props.data);
    }, [props.data]);

    useEffect(() => {
        setCharts(JSON.parse(JSON.stringify(props.template.chartsLists)));

        // Selects default first compare option.
        setTimeout(() => {
            const els: any = document.querySelectorAll(`[id^='compareTrendOptions_']`);
            els?.forEach((el: any) => el.querySelector(`.compareChecks .form-check-input`)?.click());
        }, 100);
    }, [props.template.chartsLists]);

    return (
        <div className='chartsPage'>
            {
                charts?.filter((chart: any) => !chart.tab)?.length > 0 &&
                <div className={charts?.filter((chart: any) => chart.tab)?.length > 1 ? "pb-3 mb-3" : ""}>
                    {
                        charts?.filter((chart: any) => !chart.tab)?.map((list: any, li: number) => {
                            return (
                                <>
                                    {
                                        charts?.filter((chart: any) => !chart.tab)?.length === 1 ?
                                            <div key={li}>
                                                {getTab(list, li)}
                                            </div> : null
                                    }
                                </>
                            )
                        })
                    }
                </div>
            }
            {
                charts?.filter((chart: any) => chart.tab)?.length > 1 &&
                <Tabs defaultActiveKey="tab0" activeKey={key} transition={false}
                    onSelect={(k: any) => setKey(k)}
                    onClick={() => {
                        setRtData(JSON.parse(JSON.stringify(typeof props.data === "string" ? {} : props.data)))
                    }}>
                    {
                        charts?.filter((chart: any) => chart.tab)?.map((list: any, li: number) => {
                            return charts?.filter((chart: any) => chart.tab)?.length > 1 ?
                                <Tab key={li + (new Date().getTime())} eventKey={`tab${li}`} title={list.label}>
                                    {getTab(list, li)}
                                </Tab> :
                                null
                        })
                    }
                </Tabs>
            }
        </div>
    )
}